body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  margin: 0;
}

.centered {
  display: flex;
  justify-content: center;
  margin-top: 450px;
}
.error {
  color: #d32f2f;
  font-size: 12px;
  margin-top: -5px;
}

.active {
  color: #555;
  .MuiListItemButton-root.MuiListItemButton-gutters.MuiButtonBase-root {
    background-color: #eee;
  }
}

.values_container {
  margin-bottom: 12px;
}
