.nav-link {
  text-decoration: none;
  color: #000000;
}

.nav-link-home {
  text-decoration: none;
  color: #fff;
}

.toolbar-logo {
  display: flex;
  padding: 0 40px;
  background-color: #aad3e8;
}

.arrow-icon {
  color: #fff;
}
